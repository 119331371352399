import React from 'react';
import styled from 'styled-components';
import Button from '../styleguide/Button';
import Center from '../styleguide/Center';
import ButtonWrapper from '../styleguide/ButtonWrapper';
import Progresser from './Progresser';
import { HorizontalSpacer, VerticalSpacer } from '../styleguide/Spacer';
import ContentSizer from '../styleguide/ContentSizer';
import Section from '../styleguide/Section';
import Card from '../styleguide/Card';
import Block from '../styleguide/Block';
import Text from '../styleguide/Text';

const IllustrationWrapper = styled.div`
  position: absolute;
  width: 200px;
  bottom: -4rem;
  left: 4rem;

  img {
    width: 100%;
  }

  @media all and (max-width: ${(props) => props.theme.mediaQueries.medium}) {
    position: static;
  }
`;

function Quiz({ quizData, currentStep, nextStep }) {
  const question = quizData[currentStep].question;
  const illustration = quizData[currentStep].image;
  const step = quizData[currentStep].step;
  const quizButtonYesRef = React.createRef();
  const quizButtonNoRef = React.createRef();

  window.history.pushState(null, null, `${currentStep + 1}`);

  return (
    <VerticalSpacer>
      <HorizontalSpacer>
        <ContentSizer>
          <Section>
            <Card>
              <Block centered tighter>
                <Center>
                  <Progresser visualStep={step} />
                </Center>
                <Text
                  variant="subtitle"
                  color="primary"
                  align="center"
                  gutterBottom="large"
                  gutterTop="xlarge"
                  width={step === 5 ? '75%' : undefined}
                >
                  {question}
                </Text>
                <ButtonWrapper>
                  <Button
                    ref={quizButtonYesRef}
                    outlined
                    title="Ja"
                    onClick={() => {
                      nextStep('true');
                      quizButtonYesRef.current.blur();
                    }}
                  >
                    Ja
                  </Button>
                </ButtonWrapper>
                <ButtonWrapper>
                  <Button
                    ref={quizButtonNoRef}
                    outlined
                    title="Nei"
                    onClick={() => {
                      nextStep('false');
                      quizButtonNoRef.current.blur();
                    }}
                  >
                    Nei
                  </Button>
                </ButtonWrapper>
              </Block>
              <IllustrationWrapper>
                <img src={illustration} alt="Illustrasjon" />
              </IllustrationWrapper>
            </Card>
          </Section>
        </ContentSizer>
      </HorizontalSpacer>
    </VerticalSpacer>
  );
}

export default Quiz;
