const receivers = [
  {
    region: 'Øst',
    county: 'Viken',
    commune: 'Bærum',
  },
  {
    region: 'Øst',
    county: 'Viken',
    commune: 'Aurskog-Høland',
  },
  {
    region: 'Øst',
    county: 'Viken',
    commune: 'Asker',
  },
  {
    region: 'Øst',
    county: 'Viken',
    commune: 'Aremark',
  },
  {
    region: 'Øst',
    county: 'Oslo',
    commune: 'Oslo',
  },
  {
    region: 'Øst',
    county: 'Viken',
    commune: 'Lillestrøm',
  },
  {
    region: 'Øst',
    county: 'Viken',
    commune: 'Indre Østfold',
  },
  {
    region: 'Øst',
    county: 'Viken',
    commune: 'Hvaler',
  },
  {
    region: 'Øst',
    county: 'Viken',
    commune: 'Hurdal',
  },
  {
    region: 'Øst',
    county: 'Viken',
    commune: 'Halden',
  },
  {
    region: 'Øst',
    county: 'Viken',
    commune: 'Gjerdrum',
  },
  {
    region: 'Øst',
    county: 'Viken',
    commune: 'Frogn',
  },
  {
    region: 'Øst',
    county: 'Viken',
    commune: 'Fredrikstad',
  },
  {
    region: 'Øst',
    county: 'Viken',
    commune: 'Enebakk',
  },
  {
    region: 'Øst',
    county: 'Viken',
    commune: 'Eidsvoll',
  },
  {
    region: 'Øst',
    county: 'Viken',
    commune: 'Rælingen',
  },
  {
    region: 'Øst',
    county: 'Viken',
    commune: 'Rakkestad',
  },
  {
    region: 'Øst',
    county: 'Viken',
    commune: 'Nordre Follo',
  },
  {
    region: 'Øst',
    county: 'Viken',
    commune: 'Nittedal',
  },
  {
    region: 'Øst',
    county: 'Viken',
    commune: 'Nesodden',
  },
  {
    region: 'Øst',
    county: 'Viken',
    commune: 'Nannestad',
  },
  {
    region: 'Øst',
    county: 'Viken',
    commune: 'Moss',
  },
  {
    region: 'Øst',
    county: 'Viken',
    commune: 'Marker',
  },
  {
    region: 'Øst',
    county: 'Viken',
    commune: 'Lørenskog',
  },
  {
    region: 'Øst',
    county: 'Innlandet',
    commune: 'Eidskog',
  },
  {
    region: 'Øst',
    county: 'Innlandet',
    commune: 'Dovre',
  },
  {
    region: 'Øst',
    county: 'Innlandet',
    commune: 'Alvdal',
  },
  {
    region: 'Øst',
    county: 'Viken',
    commune: 'Ås',
  },
  {
    region: 'Øst',
    county: 'Viken',
    commune: 'Våler',
  },
  {
    region: 'Øst',
    county: 'Viken',
    commune: 'Vestby',
  },
  {
    region: 'Øst',
    county: 'Viken',
    commune: 'Ullensaker',
  },
  {
    region: 'Øst',
    county: 'Viken',
    commune: 'Skiptvet',
  },
  {
    region: 'Øst',
    county: 'Viken',
    commune: 'Sarpsborg',
  },
  {
    region: 'Øst',
    county: 'Viken',
    commune: 'Råde',
  },
  {
    region: 'Øst',
    county: 'Innlandet',
    commune: 'Jevnaker',
  },
  {
    region: 'Øst',
    county: 'Innlandet',
    commune: 'Hamar',
  },
  {
    region: 'Øst',
    county: 'Innlandet',
    commune: 'Grue',
  },
  {
    region: 'Øst',
    county: 'Innlandet',
    commune: 'Gran',
  },
  {
    region: 'Øst',
    county: 'Innlandet',
    commune: 'Gjøvik',
  },
  {
    region: 'Øst',
    county: 'Innlandet',
    commune: 'Gausdal',
  },
  {
    region: 'Øst',
    county: 'Innlandet',
    commune: 'Folldal',
  },
  {
    region: 'Øst',
    county: 'Innlandet',
    commune: 'Etnedal',
  },
  {
    region: 'Øst',
    county: 'Innlandet',
    commune: 'Engerdal',
  },
  {
    region: 'Øst',
    county: 'Innlandet',
    commune: 'Elverum',
  },
  {
    region: 'Øst',
    county: 'Innlandet',
    commune: 'Nordre Land',
  },
  {
    region: 'Øst',
    county: 'Innlandet',
    commune: 'Nord-Odal',
  },
  {
    region: 'Øst',
    county: 'Innlandet',
    commune: 'Nord-Fron',
  },
  {
    region: 'Øst',
    county: 'Innlandet',
    commune: 'Nord-Aurdal',
  },
  {
    region: 'Øst',
    county: 'Innlandet',
    commune: 'Løten',
  },
  {
    region: 'Øst',
    county: 'Innlandet',
    commune: 'Lunner',
  },
  {
    region: 'Øst',
    county: 'Innlandet',
    commune: 'Lom',
  },
  {
    region: 'Øst',
    county: 'Innlandet',
    commune: 'Lillehammer',
  },
  {
    region: 'Øst',
    county: 'Innlandet',
    commune: 'Lesja',
  },
  {
    region: 'Øst',
    county: 'Innlandet',
    commune: 'Kongsvinger',
  },
  {
    region: 'Øst',
    county: 'Innlandet',
    commune: 'Stor-Elvdal',
  },
  {
    region: 'Øst',
    county: 'Innlandet',
    commune: 'Stange',
  },
  {
    region: 'Øst',
    county: 'Innlandet',
    commune: 'Skjåk',
  },
  {
    region: 'Øst',
    county: 'Innlandet',
    commune: 'Sel',
  },
  {
    region: 'Øst',
    county: 'Innlandet',
    commune: 'Ringsaker',
  },
  {
    region: 'Øst',
    county: 'Innlandet',
    commune: 'Ringebu',
  },
  {
    region: 'Øst',
    county: 'Innlandet',
    commune: 'Rendalen',
  },
  {
    region: 'Øst',
    county: 'Innlandet',
    commune: 'Os',
  },
  {
    region: 'Øst',
    county: 'Innlandet',
    commune: 'Vestre Toten',
  },
  {
    region: 'Øst',
    county: 'Innlandet',
    commune: 'Vestre Slidre',
  },
  {
    region: 'Øst',
    county: 'Innlandet',
    commune: 'Vang',
  },
  {
    region: 'Øst',
    county: 'Innlandet',
    commune: 'Tynset',
  },
  {
    region: 'Øst',
    county: 'Innlandet',
    commune: 'Trysil',
  },
  {
    region: 'Øst',
    county: 'Innlandet',
    commune: 'Trysil',
  },
  {
    region: 'Øst',
    county: 'Innlandet',
    commune: 'Tolga',
  },
  {
    region: 'Øst',
    county: 'Innlandet',
    commune: 'Sør-Odal',
  },
  {
    region: 'Øst',
    county: 'Innlandet',
    commune: 'Sør-Fron',
  },
  {
    region: 'Øst',
    county: 'Innlandet',
    commune: 'Sør-Aurdal',
  },
  {
    region: 'Øst',
    county: 'Innlandet',
    commune: 'Søndre Land',
  },
  {
    region: 'Øst',
    county: 'Innlandet',
    commune: 'Åsnes',
  },
  {
    region: 'Øst',
    county: 'Innlandet',
    commune: 'Åmot',
  },
  {
    region: 'Øst',
    county: 'Innlandet',
    commune: 'Øystre Slidre',
  },
  {
    region: 'Øst',
    county: 'Innlandet',
    commune: 'Øyer',
  },
  {
    region: 'Øst',
    county: 'Innlandet',
    commune: 'Østre Toten',
  },
  {
    region: 'Øst',
    county: 'Innlandet',
    commune: 'Våler',
  },
  {
    region: 'Øst',
    county: 'Innlandet',
    commune: 'Vågå',
  },
  {
    region: 'Nord',
    county: 'Troms og Finnmark',
    commune: 'Harstad',
  },
  {
    region: 'Nord',
    county: 'Troms og Finnmark',
    commune: 'Hammerfest',
  },
  {
    region: 'Nord',
    county: 'Troms og Finnmark',
    commune: 'Gratangen',
  },
  {
    region: 'Nord',
    county: 'Troms og Finnmark',
    commune: 'Gamvik',
  },
  {
    region: 'Nord',
    county: 'Troms og Finnmark',
    commune: 'Dyrøy',
  },
  {
    region: 'Nord',
    county: 'Troms og Finnmark',
    commune: 'Båtsfjord',
  },
  {
    region: 'Nord',
    county: 'Troms og Finnmark',
    commune: 'Berlevåg',
  },
  {
    region: 'Nord',
    county: 'Troms og Finnmark',
    commune: 'Bardu',
  },
  {
    region: 'Nord',
    county: 'Troms og Finnmark',
    commune: 'Balsfjord',
  },
  {
    region: 'Nord',
    county: 'Troms og Finnmark',
    commune: 'Alta',
  },
  {
    region: 'Nord',
    county: 'Troms og Finnmark',
    commune: 'Lebesby',
  },
  {
    region: 'Nord',
    county: 'Troms og Finnmark',
    commune: 'Lavangen',
  },
  {
    region: 'Nord',
    county: 'Troms og Finnmark',
    commune: 'Kåfjord',
  },
  {
    region: 'Nord',
    county: 'Troms og Finnmark',
    commune: 'Kvænangen',
  },
  {
    region: 'Nord',
    county: 'Troms og Finnmark',
    commune: 'Kvæfjord',
  },
  {
    region: 'Nord',
    county: 'Troms og Finnmark',
    commune: 'Kautokeino',
  },
  {
    region: 'Nord',
    county: 'Troms og Finnmark',
    commune: 'Karlsøy',
  },
  {
    region: 'Nord',
    county: 'Troms og Finnmark',
    commune: 'Karasjok',
  },
  {
    region: 'Nord',
    county: 'Troms og Finnmark',
    commune: 'Ibestad',
  },
  {
    region: 'Nord',
    county: 'Troms og Finnmark',
    commune: 'Hasvik',
  },
  {
    region: 'Nord',
    county: 'Troms og Finnmark',
    commune: 'Senja',
  },
  {
    region: 'Nord',
    county: 'Troms og Finnmark',
    commune: 'Salangen',
  },
  {
    region: 'Nord',
    county: 'Troms og Finnmark',
    commune: 'Porsanger',
  },
  {
    region: 'Nord',
    county: 'Troms og Finnmark',
    commune: 'Nordreisa',
  },
  {
    region: 'Nord',
    county: 'Troms og Finnmark',
    commune: 'Nordkapp',
  },
  {
    region: 'Nord',
    county: 'Troms og Finnmark',
    commune: 'Nesseby',
  },
  {
    region: 'Nord',
    county: 'Troms og Finnmark',
    commune: 'Måsøy',
  },
  {
    region: 'Nord',
    county: 'Troms og Finnmark',
    commune: 'Målselv',
  },
  {
    region: 'Nord',
    county: 'Troms og Finnmark',
    commune: 'Lyngen',
  },
  {
    region: 'Nord',
    county: 'Troms og Finnmark',
    commune: 'Loppa',
  },
  {
    region: 'Nord',
    county: 'Troms og Finnmark',
    commune: 'Vardø',
  },
  {
    region: 'Nord',
    county: 'Troms og Finnmark',
    commune: 'Vadsø',
  },
  {
    region: 'Nord',
    county: 'Troms og Finnmark',
    commune: 'Tromsø',
  },
  {
    region: 'Nord',
    county: 'Troms og Finnmark',
    commune: 'Tana',
  },
  {
    region: 'Nord',
    county: 'Troms og Finnmark',
    commune: 'Sørreisa',
  },
  {
    region: 'Nord',
    county: 'Troms og Finnmark',
    commune: 'Sør-Varanger',
  },
  {
    region: 'Nord',
    county: 'Troms og Finnmark',
    commune: 'Storfjord',
  },
  {
    region: 'Nord',
    county: 'Troms og Finnmark',
    commune: 'Skjervøy',
  },
  {
    region: 'Nord',
    county: 'Nordland',
    commune: 'Evenes',
  },
  {
    region: 'Nord',
    county: 'Nordland',
    commune: 'Dønna',
  },
  {
    region: 'Nord',
    county: 'Nordland',
    commune: 'Bø',
  },
  {
    region: 'Nord',
    county: 'Nordland',
    commune: 'Brønnøy',
  },
  {
    region: 'Nord',
    county: 'Nordland',
    commune: 'Bodø',
  },
  {
    region: 'Nord',
    county: 'Nordland',
    commune: 'Bindal',
  },
  {
    region: 'Nord',
    county: 'Nordland',
    commune: 'Beiarn',
  },
  {
    region: 'Nord',
    county: 'Nordland',
    commune: 'Andøy',
  },
  {
    region: 'Nord',
    county: 'Nordland',
    commune: 'Alstahaug',
  },
  {
    region: 'Nord',
    county: 'Nordland',
    commune: 'Hattjelldal',
  },
  {
    region: 'Nord',
    county: 'Nordland',
    commune: 'Hamarøy',
  },
  {
    region: 'Nord',
    county: 'Nordland',
    commune: 'Hadsel',
  },
  {
    region: 'Nord',
    county: 'Nordland',
    commune: 'Grane',
  },
  {
    region: 'Nord',
    county: 'Nordland',
    commune: 'Gildeskål',
  },
  {
    region: 'Nord',
    county: 'Nordland',
    commune: 'Flakstad',
  },
  {
    region: 'Nord',
    county: 'Nordland',
    commune: 'Fauske',
  },
  {
    region: 'Nord',
    county: 'Nordland',
    commune: 'Narvik',
  },
  {
    region: 'Nord',
    county: 'Nordland',
    commune: 'Moskenes',
  },
  {
    region: 'Nord',
    county: 'Nordland',
    commune: 'Meløy',
  },
  {
    region: 'Nord',
    county: 'Nordland',
    commune: 'Lødingen',
  },
  {
    region: 'Nord',
    county: 'Nordland',
    commune: 'Lurøy',
  },
  {
    region: 'Nord',
    county: 'Nordland',
    commune: 'Leirfjord',
  },
  {
    region: 'Nord',
    county: 'Nordland',
    commune: 'Herøy',
  },
  {
    region: 'Nord',
    county: 'Nordland',
    commune: 'Hemnes',
  },
  {
    region: 'Nord',
    county: 'Nordland',
    commune: 'Sørfold',
  },
  {
    region: 'Nord',
    county: 'Nordland',
    commune: 'Sønma',
  },
  {
    region: 'Nord',
    county: 'Nordland',
    commune: 'Steigen',
  },
  {
    region: 'Nord',
    county: 'Nordland',
    commune: 'Sortland',
  },
  {
    region: 'Nord',
    county: 'Nordland',
    commune: 'Saltdal',
  },
  {
    region: 'Nord',
    county: 'Nordland',
    commune: 'Røst',
  },
  {
    region: 'Nord',
    county: 'Nordland',
    commune: 'Rødøy',
  },
  {
    region: 'Nord',
    county: 'Nordland',
    commune: 'Rana',
  },
  {
    region: 'Nord',
    county: 'Nordland',
    commune: 'Nesna',
  },
  {
    region: 'Sør',
    county: 'Viken',
    commune: 'Flå',
  },
  {
    region: 'Sør',
    county: 'Viken',
    commune: 'Flesberg',
  },
  {
    region: 'Sør',
    county: 'Viken',
    commune: 'Drammen',
  },
  {
    region: 'Nord',
    county: 'Nordland',
    commune: 'Øksnes',
  },
  {
    region: 'Nord',
    county: 'Nordland',
    commune: 'Vågan',
  },
  {
    region: 'Nord',
    county: 'Nordland',
    commune: 'Værøy',
  },
  {
    region: 'Nord',
    county: 'Nordland',
    commune: 'Vevelstad',
  },
  {
    region: 'Nord',
    county: 'Nordland',
    commune: 'Vestvågøy',
  },
  {
    region: 'Nord',
    county: 'Nordland',
    commune: 'Vega',
  },
  {
    region: 'Nord',
    county: 'Nordland',
    commune: 'Vefsn',
  },
  {
    region: 'Nord',
    county: 'Nordland',
    commune: 'Træna',
  },
  {
    region: 'Nord',
    county: 'Nordland',
    commune: 'Tjeldsund',
  },
  {
    region: 'Sør',
    county: 'Viken',
    commune: 'Nore og Uvdal',
  },
  {
    region: 'Sør',
    county: 'Viken',
    commune: 'Nesbyen',
  },
  {
    region: 'Sør',
    county: 'Viken',
    commune: 'Nes',
  },
  {
    region: 'Sør',
    county: 'Viken',
    commune: 'Modum',
  },
  {
    region: 'Sør',
    county: 'Viken',
    commune: 'Lier',
  },
  {
    region: 'Sør',
    county: 'Viken',
    commune: 'Krødsherad',
  },
  {
    region: 'Sør',
    county: 'Viken',
    commune: 'Kongsberg',
  },
  {
    region: 'Sør',
    county: 'Viken',
    commune: 'Hole',
  },
  {
    region: 'Sør',
    county: 'Viken',
    commune: 'Hol',
  },
  {
    region: 'Sør',
    county: 'Viken',
    commune: 'Hemsedal',
  },
  {
    region: 'Sør',
    county: 'Viken',
    commune: 'Gol',
  },
  {
    region: 'Sør',
    county: 'Viken',
    commune: 'Rollag',
  },
  {
    region: 'Sør',
    county: 'Viken',
    commune: 'Ringerike',
  },
  {
    region: 'Sør',
    county: 'Vestfold og Telemark',
    commune: 'Færder',
  },
  {
    region: 'Sør',
    county: 'Vestfold og Telemark',
    commune: 'Fyresdal',
  },
  {
    region: 'Sør',
    county: 'Vestfold og Telemark',
    commune: 'Drangedal',
  },
  {
    region: 'Sør',
    county: 'Vestfold og Telemark',
    commune: 'Bamble',
  },
  {
    region: 'Sør',
    county: 'Viken',
    commune: 'Ål',
  },
  {
    region: 'Sør',
    county: 'Viken',
    commune: 'Øvre Eiker',
  },
  {
    region: 'Sør',
    county: 'Viken',
    commune: 'Sigdal',
  },
  {
    region: 'Sør',
    county: 'Vestfold og Telemark',
    commune: 'Porsgrunn',
  },
  {
    region: 'Sør',
    county: 'Vestfold og Telemark',
    commune: 'Notodden',
  },
  {
    region: 'Sør',
    county: 'Vestfold og Telemark',
    commune: 'Nome',
  },
  {
    region: 'Sør',
    county: 'Vestfold og Telemark',
    commune: 'Nissedal',
  },
  {
    region: 'Sør',
    county: 'Vestfold og Telemark',
    commune: 'Midt-Telemark',
  },
  {
    region: 'Sør',
    county: 'Vestfold og Telemark',
    commune: 'Larvik',
  },
  {
    region: 'Sør',
    county: 'Vestfold og Telemark',
    commune: 'Kviteseid',
  },
  {
    region: 'Sør',
    county: 'Vestfold og Telemark',
    commune: 'Kragerø',
  },
  {
    region: 'Sør',
    county: 'Vestfold og Telemark',
    commune: 'Horten',
  },
  {
    region: 'Sør',
    county: 'Vestfold og Telemark',
    commune: 'Holmestrand',
  },
  {
    region: 'Sør',
    county: 'Vestfold og Telemark',
    commune: 'Hjartdal',
  },
  {
    region: 'Vest',
    county: 'Agder',
    commune: 'Birkenes',
  },
  {
    region: 'Vest',
    county: 'Agder',
    commune: 'Arendal',
  },
  {
    region: 'Sør',
    county: 'Vestfold og Telemark',
    commune: 'Vinje',
  },
  {
    region: 'Sør',
    county: 'Vestfold og Telemark',
    commune: 'Tønsberg',
  },
  {
    region: 'Sør',
    county: 'Vestfold og Telemark',
    commune: 'Tokke',
  },
  {
    region: 'Sør',
    county: 'Vestfold og Telemark',
    commune: 'Tinn',
  },
  {
    region: 'Sør',
    county: 'Vestfold og Telemark',
    commune: 'Skien',
  },
  {
    region: 'Sør',
    county: 'Vestfold og Telemark',
    commune: 'Siljan',
  },
  {
    region: 'Sør',
    county: 'Vestfold og Telemark',
    commune: 'Seljord',
  },
  {
    region: 'Sør',
    county: 'Vestfold og Telemark',
    commune: 'Sandefjord',
  },
  {
    region: 'Vest',
    county: 'Agder',
    commune: 'Kristiansand',
  },
  {
    region: 'Vest',
    county: 'Agder',
    commune: 'Iveland',
  },
  {
    region: 'Vest',
    county: 'Agder',
    commune: 'Hægebostad',
  },
  {
    region: 'Vest',
    county: 'Agder',
    commune: 'Grimstad',
  },
  {
    region: 'Vest',
    county: 'Agder',
    commune: 'Gjerstad',
  },
  {
    region: 'Vest',
    county: 'Agder',
    commune: 'Froland',
  },
  {
    region: 'Vest',
    county: 'Agder',
    commune: 'Flekkefjord',
  },
  {
    region: 'Vest',
    county: 'Agder',
    commune: 'Farsund',
  },
  {
    region: 'Vest',
    county: 'Agder',
    commune: 'Evje og Hornnes',
  },
  {
    region: 'Vest',
    county: 'Agder',
    commune: 'Bykle',
  },
  {
    region: 'Vest',
    county: 'Agder',
    commune: 'Bygland',
  },
  {
    region: 'Vest',
    county: 'Agder',
    commune: 'Lindesnes',
  },
  {
    region: 'Vest',
    county: 'Agder',
    commune: 'Lillesand',
  },
  {
    region: 'Vest',
    county: 'Agder',
    commune: 'Kvinesdal',
  },
  {
    region: 'Vest',
    county: 'Rogaland',
    commune: 'Eigersund',
  },
  {
    region: 'Vest',
    county: 'Rogaland',
    commune: 'Bokn',
  },
  {
    region: 'Vest',
    county: 'Rogaland',
    commune: 'Bjerkreim',
  },
  {
    region: 'Vest',
    county: 'Agder',
    commune: 'Åseral',
  },
  {
    region: 'Vest',
    county: 'Agder',
    commune: 'Åmli',
  },
  {
    region: 'Vest',
    county: 'Agder',
    commune: 'Vennesla',
  },
  {
    region: 'Vest',
    county: 'Agder',
    commune: 'Vegårshei',
  },
  {
    region: 'Vest',
    county: 'Agder',
    commune: 'Valle',
  },
  {
    region: 'Vest',
    county: 'Agder',
    commune: 'Tvedestrand',
  },
  {
    region: 'Vest',
    county: 'Agder',
    commune: 'Sirdal',
  },
  {
    region: 'Vest',
    county: 'Agder',
    commune: 'Risør',
  },
  {
    region: 'Vest',
    county: 'Agder',
    commune: 'Lyngdal',
  },
  {
    region: 'Vest',
    county: 'Rogaland',
    commune: 'Randaberg',
  },
  {
    region: 'Vest',
    county: 'Rogaland',
    commune: 'Lund',
  },
  {
    region: 'Vest',
    county: 'Rogaland',
    commune: 'Kvitsøy',
  },
  {
    region: 'Vest',
    county: 'Rogaland',
    commune: 'Klepp',
  },
  {
    region: 'Vest',
    county: 'Rogaland',
    commune: 'Karmøy',
  },
  {
    region: 'Vest',
    county: 'Rogaland',
    commune: 'Hå',
  },
  {
    region: 'Vest',
    county: 'Rogaland',
    commune: 'Hjelmeland',
  },
  {
    region: 'Vest',
    county: 'Rogaland',
    commune: 'Haugesund',
  },
  {
    region: 'Vest',
    county: 'Rogaland',
    commune: 'Gjesdal',
  },
  {
    region: 'Vest',
    county: 'Vestland',
    commune: 'Alver',
  },
  {
    region: 'Vest',
    county: 'Rogaland',
    commune: 'Vindafjord',
  },
  {
    region: 'Vest',
    county: 'Rogaland',
    commune: 'Utsira',
  },
  {
    region: 'Vest',
    county: 'Rogaland',
    commune: 'Tysvær',
  },
  {
    region: 'Vest',
    county: 'Rogaland',
    commune: 'Time',
  },
  {
    region: 'Vest',
    county: 'Rogaland',
    commune: 'Suldal',
  },
  {
    region: 'Vest',
    county: 'Rogaland',
    commune: 'Strand',
  },
  {
    region: 'Vest',
    county: 'Rogaland',
    commune: 'Stavanger',
  },
  {
    region: 'Vest',
    county: 'Rogaland',
    commune: 'Sola',
  },
  {
    region: 'Vest',
    county: 'Rogaland',
    commune: 'Sokndal',
  },
  {
    region: 'Vest',
    county: 'Rogaland',
    commune: 'Sauda',
  },
  {
    region: 'Vest',
    county: 'Rogaland',
    commune: 'Sandnes',
  },
  {
    region: 'Vest',
    county: 'Vestland',
    commune: 'Etne',
  },
  {
    region: 'Vest',
    county: 'Vestland',
    commune: 'Eidfjord',
  },
  {
    region: 'Vest',
    county: 'Vestland',
    commune: 'Bømlo',
  },
  {
    region: 'Vest',
    county: 'Vestland',
    commune: 'Bremanger',
  },
  {
    region: 'Vest',
    county: 'Vestland',
    commune: 'Bjørnafjorden',
  },
  {
    region: 'Vest',
    county: 'Vestland',
    commune: 'Bergen',
  },
  {
    region: 'Vest',
    county: 'Vestland',
    commune: 'Austrheim',
  },
  {
    region: 'Vest',
    county: 'Vestland',
    commune: 'Austevoll',
  },
  {
    region: 'Vest',
    county: 'Vestland',
    commune: 'Aurland',
  },
  {
    region: 'Vest',
    county: 'Vestland',
    commune: 'Askøy',
  },
  {
    region: 'Vest',
    county: 'Vestland',
    commune: 'Askvoll',
  },
  {
    region: 'Vest',
    county: 'Vestland',
    commune: 'Kvinnherad',
  },
  {
    region: 'Vest',
    county: 'Vestland',
    commune: 'Kvam',
  },
  {
    region: 'Vest',
    county: 'Vestland',
    commune: 'Kvam',
  },
  {
    region: 'Vest',
    county: 'Vestland',
    commune: 'Kinn',
  },
  {
    region: 'Vest',
    county: 'Vestland',
    commune: 'Høyanger',
  },
  {
    region: 'Vest',
    county: 'Vestland',
    commune: 'Hyllestad',
  },
  {
    region: 'Vest',
    county: 'Vestland',
    commune: 'Gulen',
  },
  {
    region: 'Vest',
    county: 'Vestland',
    commune: 'Gloppen',
  },
  {
    region: 'Vest',
    county: 'Vestland',
    commune: 'Fjaler',
  },
  {
    region: 'Vest',
    county: 'Vestland',
    commune: 'Fitjar',
  },
  {
    region: 'Vest',
    county: 'Vestland',
    commune: 'Fedje',
  },
  {
    region: 'Vest',
    county: 'Vestland',
    commune: 'Stryn',
  },
  {
    region: 'Vest',
    county: 'Vestland',
    commune: 'Stord',
  },
  {
    region: 'Vest',
    county: 'Vestland',
    commune: 'Stad',
  },
  {
    region: 'Vest',
    county: 'Vestland',
    commune: 'Solund',
  },
  {
    region: 'Vest',
    county: 'Vestland',
    commune: 'Sogndal',
  },
  {
    region: 'Vest',
    county: 'Vestland',
    commune: 'Samnanger',
  },
  {
    region: 'Vest',
    county: 'Vestland',
    commune: 'Osterøy',
  },
  {
    region: 'Vest',
    county: 'Vestland',
    commune: 'Modalen',
  },
  {
    region: 'Vest',
    county: 'Vestland',
    commune: 'Masfjorden',
  },
  {
    region: 'Vest',
    county: 'Vestland',
    commune: 'Lærdal',
  },
  {
    region: 'Vest',
    county: 'Vestland',
    commune: 'Luster',
  },
  {
    region: 'Midt-Norge',
    county: 'Møre og Romsdal',
    commune: 'Aure',
  },
  {
    region: 'Midt-Norge',
    county: 'Møre og Romsdal',
    commune: 'Aukra',
  },
  {
    region: 'Vest',
    county: 'Vestland',
    commune: 'Årdal',
  },
  {
    region: 'Vest',
    county: 'Vestland',
    commune: 'Øygarden',
  },
  {
    region: 'Vest',
    county: 'Vestland',
    commune: 'Voss',
  },
  {
    region: 'Vest',
    county: 'Vestland',
    commune: 'Vik',
  },
  {
    region: 'Vest',
    county: 'Vestland',
    commune: 'Vaksdal',
  },
  {
    region: 'Vest',
    county: 'Vestland',
    commune: 'Ulvik',
  },
  {
    region: 'Vest',
    county: 'Vestland',
    commune: 'Ullensvang',
  },
  {
    region: 'Vest',
    county: 'Vestland',
    commune: 'Tysnes',
  },
  {
    region: 'Vest',
    county: 'Vestland',
    commune: 'Sveio',
  },
  {
    region: 'Vest',
    county: 'Vestland',
    commune: 'Sunnfjord',
  },
  {
    region: 'Midt-Norge',
    county: 'Møre og Romsdal',
    commune: 'Rindal',
  },
  {
    region: 'Midt-Norge',
    county: 'Møre og Romsdal',
    commune: 'Rauma',
  },
  {
    region: 'Midt-Norge',
    county: 'Møre og Romsdal',
    commune: 'Molde',
  },
  {
    region: 'Midt-Norge',
    county: 'Møre og Romsdal',
    commune: 'Kristiansund',
  },
  {
    region: 'Midt-Norge',
    county: 'Møre og Romsdal',
    commune: 'Hustadvika',
  },
  {
    region: 'Midt-Norge',
    county: 'Møre og Romsdal',
    commune: 'Herøy',
  },
  {
    region: 'Midt-Norge',
    county: 'Møre og Romsdal',
    commune: 'Hareid',
  },
  {
    region: 'Midt-Norge',
    county: 'Møre og Romsdal',
    commune: 'Gjemnes',
  },
  {
    region: 'Midt-Norge',
    county: 'Møre og Romsdal',
    commune: 'Giske',
  },
  {
    region: 'Midt-Norge',
    county: 'Møre og Romsdal',
    commune: 'Fjord',
  },
  {
    region: 'Midt-Norge',
    county: 'Møre og Romsdal',
    commune: 'Averøy',
  },
  {
    region: 'Midt-Norge',
    county: 'Møre og Romsdal',
    commune: 'Volda',
  },
  {
    region: 'Midt-Norge',
    county: 'Møre og Romsdal',
    commune: 'Vestnes',
  },
  {
    region: 'Midt-Norge',
    county: 'Møre og Romsdal',
    commune: 'Vanylven',
  },
  {
    region: 'Midt-Norge',
    county: 'Møre og Romsdal',
    commune: 'Ulstein',
  },
  {
    region: 'Midt-Norge',
    county: 'Møre og Romsdal',
    commune: 'Tingvoll',
  },
  {
    region: 'Midt-Norge',
    county: 'Møre og Romsdal',
    commune: 'Sykkylven',
  },
  {
    region: 'Midt-Norge',
    county: 'Møre og Romsdal',
    commune: 'Surnadal',
  },
  {
    region: 'Midt-Norge',
    county: 'Møre og Romsdal',
    commune: 'Sunndal',
  },
  {
    region: 'Midt-Norge',
    county: 'Møre og Romsdal',
    commune: 'Sula',
  },
  {
    region: 'Midt-Norge',
    county: 'Møre og Romsdal',
    commune: 'Stranda',
  },
  {
    region: 'Midt-Norge',
    county: 'Møre og Romsdal',
    commune: 'Smøla',
  },
  {
    region: 'Midt-Norge',
    county: 'Møre og Romsdal',
    commune: 'Sande',
  },
  {
    region: 'Midt-Norge',
    county: 'Trøndelag',
    commune: 'Indre Fosen',
  },
  {
    region: 'Midt-Norge',
    county: 'Trøndelag',
    commune: 'Inderøy',
  },
  {
    region: 'Midt-Norge',
    county: 'Trøndelag',
    commune: 'Høylandet',
  },
  {
    region: 'Midt-Norge',
    county: 'Trøndelag',
    commune: 'Holtålen',
  },
  {
    region: 'Midt-Norge',
    county: 'Trøndelag',
    commune: 'Hitra',
  },
  {
    region: 'Midt-Norge',
    county: 'Trøndelag',
    commune: 'Heim',
  },
  {
    region: 'Midt-Norge',
    county: 'Trøndelag',
    commune: 'Grong',
  },
  {
    region: 'Midt-Norge',
    county: 'Trøndelag',
    commune: 'Frøya',
  },
  {
    region: 'Midt-Norge',
    county: 'Trøndelag',
    commune: 'Frosta',
  },
  {
    region: 'Midt-Norge',
    county: 'Trøndelag',
    commune: 'Flatanger',
  },
  {
    region: 'Midt-Norge',
    county: 'Møre og Romsdal',
    commune: 'Ålesund',
  },
  {
    region: 'Midt-Norge',
    county: 'Møre og Romsdal',
    commune: 'Ørsta',
  },
  {
    region: 'Midt-Norge',
    county: 'Trøndelag',
    commune: 'Melhus',
  },
  {
    region: 'Midt-Norge',
    county: 'Trøndelag',
    commune: 'Malvik',
  },
  {
    region: 'Midt-Norge',
    county: 'Trøndelag',
    commune: 'Lierne',
  },
  {
    region: 'Midt-Norge',
    county: 'Trøndelag',
    commune: 'Levanger',
  },
  {
    region: 'Midt-Norge',
    county: 'Trøndelag',
    commune: 'Leka',
  },
  {
    region: 'Midt-Norge',
    county: 'Trøndelag',
    commune: 'Osen',
  },
  {
    region: 'Midt-Norge',
    county: 'Trøndelag',
    commune: 'Orkland',
  },
  {
    region: 'Midt-Norge',
    county: 'Trøndelag',
    commune: 'Oppdal',
  },
  {
    region: 'Midt-Norge',
    county: 'Trøndelag',
    commune: 'Nærøysund',
  },
  {
    region: 'Midt-Norge',
    county: 'Trøndelag',
    commune: 'Namsskogan',
  },
  {
    region: 'Midt-Norge',
    county: 'Trøndelag',
    commune: 'Namsos',
  },
  {
    region: 'Midt-Norge',
    county: 'Trøndelag',
    commune: 'Midtre Gauldal',
  },
  {
    region: 'Midt-Norge',
    county: 'Trøndelag',
    commune: 'Meråker',
  },
  {
    region: 'Midt-Norge',
    county: 'Trøndelag',
    commune: 'Verdal',
  },
  {
    region: 'Midt-Norge',
    county: 'Trøndelag',
    commune: 'Tydal',
  },
  {
    region: 'Midt-Norge',
    county: 'Trøndelag',
    commune: 'Trondheim',
  },
  {
    region: 'Midt-Norge',
    county: 'Trøndelag',
    commune: 'Stjørdal',
  },
  {
    region: 'Midt-Norge',
    county: 'Trøndelag',
    commune: 'Steinkjer',
  },
  {
    region: 'Midt-Norge',
    county: 'Trøndelag',
    commune: 'Snåsa',
  },
  {
    region: 'Midt-Norge',
    county: 'Trøndelag',
    commune: 'Skaun',
  },
  {
    region: 'Midt-Norge',
    county: 'Trøndelag',
    commune: 'Selbu',
  },
  {
    region: 'Midt-Norge',
    county: 'Trøndelag',
    commune: 'Røyrvik',
  },
  {
    region: 'Midt-Norge',
    county: 'Trøndelag',
    commune: 'Røros',
  },
  {
    region: 'Midt-Norge',
    county: 'Trøndelag',
    commune: 'Rennebu',
  },
  {
    region: 'Midt-Norge',
    county: 'Trøndelag',
    commune: 'Overhalla',
  },
  {
    region: 'Midt-Norge',
    county: 'Trøndelag',
    commune: 'Åfjord',
  },
  {
    region: 'Midt-Norge',
    county: 'Trøndelag',
    commune: 'Ørland',
  },
];

export default receivers;
