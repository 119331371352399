import styled, { css } from 'styled-components';

const Block = styled.div`
  padding: 6rem 10rem;

  ${(props) =>
    props.flex &&
    css`
      height: 100%;
      display: flex;
      flex-direction: column;
    `};

  ${(props) =>
    props.flexValue &&
    css`
      flex: ${props.flexValue};
    `};

  ${(props) =>
    props.centered &&
    css`
      justify-content: center;
      align-items: center;
      text-align: center;
    `};

  ${(props) =>
    props.tighter &&
    css`
      padding: 4rem 12rem;
    `};

  ${(props) =>
    props.hugBottom &&
    css`
      padding-bottom: 0;
    `}

  ${(props) =>
    props.hugTop &&
    css`
      padding-top: 0;
    `}

    @media all and (max-width: ${(props) => props.theme.mediaQueries.medium}) {
      padding: 4rem 5rem;
    }
  
    @media all and (max-width: ${(props) => props.theme.mediaQueries.small}) {
      padding: 2rem 1rem;
    }
`;

export default Block;
