export default {
  gtmId: 'GTM-PKB9DPM',
  profilerUrl:
    'https://attentionplz.herokuapp.com/campaigns/5c3f236c7528510016532d7d/collectors/5c3f24577528510016532d82/collect',
  characteristics: {
    region: '5c3f23b77528510016532d7e',
    accept: '5daf09a07d0838000472cdc0',
    county: '5c3f22377528510016532d6f',
    commune: '5c3f22317528510016532d6d',
  },
};
